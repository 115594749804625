import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { match } from 'ts-pattern';
import { useScreenContext } from '../../../../context/ScreenContext/hook';

export enum ModalType {
  PEEK_A_BOO = 'PEEK_A_BOO',
  // Docked to left side of screen, full screen on mobile
  DOCKED = 'DOCKED',
  // Standard modal on desktop, bottom sheet on mobile
  STANDARD = 'STANDARD',
  // Standard modal on desktop, full screen slide up on mobile
  FULL_SCREEN_MOBILE = 'FULL_SCREEN_MOBILE',
}

export interface BaseModalProps {
  modalType?: ModalType;
  isShowing: boolean;
  onClose?: () => void;
}

export const BaseModal: React.FC<BaseModalProps> = ({
  children,
  modalType = ModalType.STANDARD,
  isShowing,
  onClose,
}) => {
  const { isSmallScreen, isMediumScreen, isLargeScreen } = useScreenContext();
  const [keyboardOpen, setKeyboardOpen] = useState<boolean>(false);

  useEffect(() => {
    function handleKeyboardListener() {
      const vh = window.innerHeight;
      const visualVh = window.visualViewport.height;

      setKeyboardOpen(visualVh < vh);
    }

    window.visualViewport.addEventListener('resize', handleKeyboardListener);

    return () => {
      window.visualViewport.removeEventListener('resize', handleKeyboardListener);
    };
  });

  const { docked, hide, show, style } = match({ isSmallScreen, isMediumScreen, isLargeScreen, modalType })
    .with({ modalType: ModalType.STANDARD, isLargeScreen: false }, () => ({
      style: 'min-h-[156px] w-screen fixed rounded-t-xl bottom-0 left-0',
      show: 'animate-slide-in-up',
      hide: 'animate-slide-out-down',
      docked: true,
    }))
    .with({ modalType: ModalType.FULL_SCREEN_MOBILE, isLargeScreen: false }, () => ({
      style: 'h-[95vh] w-screen fixed rounded-t-xl bottom-0 left-0',
      show: 'animate-slide-in-up',
      hide: 'animate-slide-out-down',
      docked: true,
    }))
    .with({ modalType: ModalType.PEEK_A_BOO }, () => ({
      style: 'w-[448px] rounded-xl overflow-hidden mx-4 sm:mx-0',
      show: 'animate-fade-in',
      hide: 'animate-fade-out',
      docked: false,
    }))
    .with({ modalType: ModalType.DOCKED, isLargeScreen: false }, () => ({
      style: 'w-[100svw] inset-0 h-[100svh] fixed top-0 left-0 rounded-xl',
      show: 'animate-slide-in-left',
      hide: 'animate-slide-out-left',
      docked: true,
    }))
    .with({ modalType: ModalType.DOCKED, isLargeScreen: true }, () => ({
      style: 'w-[400px] max-h-[95vh] fixed top-1 left-1 rounded-xl',
      show: 'animate-slide-in-left',
      hide: 'animate-slide-out-left',
      docked: true,
    }))
    .otherwise(() => ({
      style: 'w-[640px] rounded-xl',
      show: 'animate-fade-in',
      hide: 'animate-fade-out',
      docked: false,
    }));

  const [animationClass, setAnimationClass] = useState<string>(hide);
  useEffect(() => {
    if (isShowing) {
      setAnimationClass(show);
    }

    if (!isShowing) {
      setAnimationClass(hide);
    }
  }, [isShowing, hide, show, setAnimationClass]);

  const modalContent = (
    <div
      onClick={(e) => e.stopPropagation()}
      id='modal'
      className={cn('z-modal w-full border-none bg-white drop-shadow-2xl transition', animationClass, style, {
        'max-md:pb-12': keyboardOpen && modalType === ModalType.STANDARD,
      })}>
      {children}
    </div>
  );

  const modal = docked ? (
    modalContent
  ) : (
    <div
      className={cn('fixed inset-0 top-0 left-0 z-modal-backdrop flex items-center justify-center', {})}
      onClick={onClose}>
      {modalContent}
    </div>
  );

  return (
    <CSSTransition in={isShowing} unmountOnExit timeout={150}>
      {modal}
    </CSSTransition>
  );
};
