import { ClientEvents } from 'common/events/ClientEvents';
import React from 'react';
import { useZipCodeInput } from 'src/rebrand/useZipCodeInput';
import { FaLocationDot } from 'src/shared/design-system/FontAwesome';
import { useTracker } from 'src/shared/hooks/useTracker';
import { ModalType, StyledModal } from 'web-common/src/shared/design-system/components/Modal';

const HEADING = (
  <>
    <FaLocationDot /> Enter your zip code
  </>
);
const SUB_HEADING = 'Let’s see who’s cooking in your area.';

interface ZipCodeSelectionModalProps {
  isShowing: boolean;
  isMealPlan?: boolean;
  redirectUrl?: string;
  redirectOnSubmit?: boolean;
  showCloseButton?: boolean;
  hide: () => void;
}

export const ZipCodeSelectionModal: React.FC<ZipCodeSelectionModalProps> = ({
  isShowing,
  redirectUrl,
  redirectOnSubmit,
  showCloseButton,
  isMealPlan,
  hide,
}) => {
  const tracker = useTracker();

  const onZipSubmit = (zip: string) => {
    tracker.track(ClientEvents.EXPLORE_ZIP_SUBMIT, { zip });
    hide();
  };
  const { onChange, onSubmit, zipCode, cachedZipCode } = useZipCodeInput({
    redirectRoute: redirectUrl,
    isMealPlan,
    onZipSubmit,
    redirectOnSubmit,
  });

  return (
    <StyledModal
      headerText={HEADING}
      modalType={ModalType.STANDARD}
      isShowing={isShowing}
      primaryButtonText={cachedZipCode ? 'Update' : 'Find shefs near you'}
      primaryDisabled={zipCode.length < 5}
      secondaryButtonText='Cancel'
      showCloseButton={showCloseButton}
      onAccept={onSubmit}
      onClose={hide}>
      <p className='mb-4 text-left text-base text-neutral-600'>{SUB_HEADING}</p>
      <div className='flex justify-center'>
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await onSubmit();
          }}
          className='flex w-full items-center gap-2 rounded-xl border border-solid border-neutral-200 p-2 text-neutral-400'>
          <input
            className='m-0 w-full p-0 text-xl leading-none text-neutral-800 outline-none focus:outline-none active:outline-none'
            inputMode='numeric'
            placeholder='Zip code'
            type='number'
            value={zipCode}
            onChange={(e) => onChange(e.target.value)}
          />
        </form>
      </div>
    </StyledModal>
  );
};
